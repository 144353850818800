
<div :class="isPc() && 'u-wp90'">
  <div class="p-prefecture-modal-v2__selector u-bg-white" v-on:click="isModalActive = true">
    <span>{{ selectedPrefectureNames }}</span>
  </div>
  <div class="p-entry-form__input__error u-h-1em u-mt8">
    <p>
      {{ errorMessage }}
    </p>
  </div>
  <div class="v-modal p-prefecture-modal-v2" v-show="isModalActive">
    <div class="v-modal__header">
      希望勤務地（複数可）
    </div>
    <div class="v-modal__body">
      <div v-for="(prefecture, i) in prefectures" :key="`prefecture-${i}`" class="v-modal__item">
        <label :for="'prefecture' + prefecture.id" class="p-client-scout__radio-label u-color-black js-label">
          <input :id="'prefecture' + prefecture.id" type="checkbox" :value="prefecture.id"
            name="applicant[desired_workplace_id][]" class="c-checkbox -v2 -bd-1 checkbox-item" v-model="selectedPrefectureIds">
          {{ prefecture.name }}
        </label>
      </div>
    </div>
    <div class="v-modal__footer">
      <button type="button" class="c-button -border-green" v-on:click="isModalActive = false">閉じる</button>
    </div>
  </div>
  <div class="v-modal__background" v-show="isModalActive" v-on:click="isModalActive = false">
  </div>
</div>
