import Utils from './utils';
import PrefectureModalJob from './components/prefecture_modal_job.vue';
import PrefectureMultiModalJob from './components/prefecture_multi_modal_job.vue';

export default {
  components: {
    PrefectureModalJob,
    PrefectureMultiModalJob,
  },

  data() {
    return {
      isMounted: false,
      inputItems: {
        license_check: [],
        experience_check: [],
        prefecture: '',
        birthYear: '',
        name: '',
        tel: '',
        email: '',
        password: '',
        password_conf: '',
      },
      hasError: true,
      errorMessage: {
        prefecture: '',
        birthYear: '',
        name: '',
        tel: '',
        email: '',
        license: '',
        experience: '',
      },
      step: 1, // 横ステップで使用
    };
  },

  computed: {
    isTelValid() {
      this.inputItems.tel = Utils.zenkakuHankaku(this.inputItems.tel);
      return /^\d{10,11}$/.test(this.inputItems.tel);
    },
    isNameValid() {
      this.inputItems.name = Utils.zenkakuHankaku(this.inputItems.name);
      return this.inputItems.name.length > 1;
    },
    isEmailValid() {
      const VALID_EMAIL_REGEX = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i;
      this.inputItems.email = Utils.zenkakuHankaku(this.inputItems.email);
      return VALID_EMAIL_REGEX.test(this.inputItems.email);
    },
    isPrefecturesValid() {
      if (!this.isMounted) {
        return false;
      }

      return this.$refs.prefectures.isValid;
    },
    isinputItemsValid() {
      return (
        Object.values(this.inputItems).every((x) => x) &&
        this.isTelValid &&
        this.inputItems.license_check.length > 0
      );
    },
  },
  created() {
    let $slide = $('.p-horizontal-slide');
    if ($slide.length) {
      // 画面サイズ変更時
      $(window).on('resize', () => {
        $slide = $('.p-horizontal-slide');
        this.updateSlideHeight($slide);
      });
    }
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    step() {
      const $slide = $('.p-horizontal-slide');
      if ($slide.length) {
        this.updateSlideHeight($slide);
        this.scrollToSlide();
      }
    },
  },
  methods: {
    checkForm(e, offset = true) {
      this.errorMessage.prefecture = '';
      this.errorMessage.birthYear = '';
      this.errorMessage.name = '';
      this.errorMessage.tel = '';
      this.errorMessage.email = '';
      this.errorMessage.license = '';
      this.errorMessage.experience = '';

      const hasErrors = [];
      hasErrors.push(!this.isPrefecturesValid);
      hasErrors.push(this.inputItems.birthYear.length < 1);
      hasErrors.push(!this.isNameValid);
      hasErrors.push(!this.isTelValid);
      hasErrors.push(!this.isEmailValid);
      hasErrors.push(this.inputItems.license_check.length < 1);
      hasErrors.push(this.inputItems.experience_check.length < 1);

      const errorIndex = hasErrors.indexOf(true);
      if (hasErrors[0])
        this.errorMessage.prefecture = '＊「都道府県」を選択してください';
      if (hasErrors[1])
        this.errorMessage.birthYear = '＊「生まれた年」を選択してください';
      if (hasErrors[2])
        this.errorMessage.name = '＊「お名前」を入力してください';
      if (hasErrors[3])
        this.errorMessage.tel = '＊10桁か11桁の番号を入力してください';
      if (hasErrors[4])
        this.errorMessage.email =
          '＊正しい「メールアドレス」を入力してください';
      if (hasErrors[5])
        this.errorMessage.license =
          '＊「お持ちの資格」を少なくとも１つ選択してください';
      if (hasErrors[6])
        this.errorMessage.experience =
          '＊「ご経験」を少なくとも１つ選択してください';

      if (errorIndex > -1) {
        e.preventDefault();

        let jumpTo = '';
        switch (errorIndex) {
          case 0:
            jumpTo = '#prefecture';
            break;
          case 1:
            jumpTo = '#birthYear';
            break;
          case 2:
            jumpTo = '#name';
            break;
          case 3:
            jumpTo = '#tel';
            break;
          case 4:
            jumpTo = '#email';
            break;
          case 5:
            jumpTo = '#license';
            break;
          case 6:
            jumpTo = '#experience';
            break;
          default:
            jumpTo = '';
            break;
        }
        if (offset) {
          $('html, body').scrollTop($(jumpTo).offset().top - 60);
        }
      } else {
        e.target.disabled = true;
        e.target.innerText = '送信中...';
        $('form').submit();
      }
    },
    // ステップ1: 資格と経験の入力チェック
    checkFormStep1(nextStep) {
      this.errorMessage.license = '';
      this.errorMessage.experience = '';

      const hasErrors = [];
      hasErrors.push(this.inputItems.license_check.length < 1);
      hasErrors.push(this.inputItems.experience_check.length < 1);

      if (hasErrors[0])
        this.errorMessage.license =
          '＊「お持ちの資格」を少なくとも１つ選択してください';
      if (hasErrors[1])
        this.errorMessage.experience =
          '＊「ご経験」を少なくとも１つ選択してください';

      const errorIndex = hasErrors.indexOf(true);
      // エラーがない時に次のステップへ
      if (errorIndex === -1) {
        this.step = nextStep;
      }
    },
    // ステップ2: 希望勤務地、生まれた年、氏名の入力チェック
    checkFormStep2(nextStep) {
      this.errorMessage.prefecture = '';
      this.errorMessage.birthYear = '';
      this.errorMessage.name = '';

      const hasErrors = [];
      hasErrors.push(!this.isPrefecturesValid);
      hasErrors.push(this.inputItems.birthYear.length < 1);
      hasErrors.push(!this.isNameValid);

      if (hasErrors[0])
        this.errorMessage.prefecture = '＊「都道府県」を選択してください';
      if (hasErrors[1])
        this.errorMessage.birthYear = '＊「生まれた年」を選択してください';
      if (hasErrors[2])
        this.errorMessage.name = '＊「お名前」を入力してください';

      const errorIndex = hasErrors.indexOf(true);
      // エラーがない時に次のステップへ
      if (errorIndex === -1) {
        this.step = nextStep;
      }
    },
    setStep(step) {
      if (step < this.step) {
        this.step = step;
      }

      if (this.step === 1 && step === 2) {
        this.checkFormStep1(step);
      } else if (this.step === 2 && step === 3) {
        this.checkFormStep2(step);
      }
    },
    updateSlideHeight($slide) {
      if (!$slide.length) return;
      // 高さの調整は、`.p-horizontal-slide__step`のstep数に応じた個数(step=1なら1つ目)
      const $step = $('.p-horizontal-slide__step');
      $slide.height($step.eq(this.step - 1).height());
    },
    // スライドの位置までスクロール
    scrollToSlide() {
      // `#entry_form`の箇所までスクロールアニメーション
      const $header = $('.l-header');
      const headerHeight = $header.length ? $header.height() : 0;
      const $entryForm = $('#entry_form');
      const entryFormOffsetTop = $entryForm.length
        ? $entryForm.offset().top
        : 0;
      const scrollTop = entryFormOffsetTop - headerHeight;

      // smooth-scrollを一時的に無効にする
      const originalScrollBehavior = $('html').css('scroll-behavior');
      $('html').css('scroll-behavior', 'auto');
      // アニメーション完了後、元のsmooth-scrollの設定を復元する
      $('html, body').animate({ scrollTop }, 500, () => {
        $('html').css('scroll-behavior', originalScrollBehavior);
      });
    },
  },
};
